// Override default variables before the import
@import url(https://fonts.googleapis.com/css?family=Philosopher|Open+Sans:400,400italic,700,800);

$philosopher: 'Philosopher', serif;
$open-sans: 'Open Sans', sans-serif;

$font-family-sans-serif:      $open-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base:            $font-family-sans-serif !default;
$headings-font-family:        $philosopher !default;

.navbar {
  font-family: $philosopher;
}

$body-bg: #eeeeee;
$white: #ffffff;

$theme-colors: (
  "primary": #660000,
  "drachenwald": #660000,
);


$navbar-dark-color:                 rgba($white, 1) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;


.banner {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(../images/web-symp-banner.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff; 
  text-shadow: 2px 2px #000;
  // height: 300px;

  a.btn:hover {
    text-shadow: none;
  }
}

svg {
  width: 1.5rem;
}


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';